// @ts-nocheck
import {Routes} from '@angular/router';

export const NG_DOC_ROUTING: Routes = [
	
	{
		path: 'components',
		title: `Components`,
		children: [{
			path: '',
			redirectTo: 'alert',
			pathMatch: 'full'
		},
	{
		path: 'alert',
		title: `Alert`,
		loadChildren: () => import('./guides/components/alert/page'),
	},
	{
		path: 'logo',
		title: `Logo`,
		loadChildren: () => import('./guides/components/logo/page'),
	},
	{
		path: 'navigation',
		title: `Layout`,
		loadChildren: () => import('./guides/components/navigation/page'),
	},
	{
		path: 'badge',
		title: `Badge`,
		loadChildren: () => import('./guides/components/badge/page'),
	},
	{
		path: 'card',
		title: `Card`,
		loadChildren: () => import('./guides/components/card/page'),
	},
	{
		path: 'loader',
		title: `Loader`,
		loadChildren: () => import('./guides/components/loader/page'),
	},
	{
		path: 'back-to-top',
		title: `Back To Top`,
		loadChildren: () => import('./guides/components/back-to-top/page'),
	},
	{
		path: 'select',
		title: `Select`,
		loadChildren: () => import('./guides/components/select/page'),
	},
	{
		path: 'info-window',
		title: `Info Window`,
		loadChildren: () => import('./guides/components/info-window/page'),
	},
	{
		path: 'toast',
		title: `Toast`,
		loadChildren: () => import('./guides/components/toast/page'),
	},
	{
		path: 'divider',
		title: `Divider`,
		loadChildren: () => import('./guides/components/divider/page'),
	},
	{
		path: 'sheet',
		title: `Sheet`,
		loadChildren: () => import('./guides/components/sheet/page'),
	},
	{
		path: 'button',
		title: `Button`,
		loadChildren: () => import('./guides/components/button/page'),
	},
	{
		path: 'switch',
		title: `Switch`,
		loadChildren: () => import('./guides/components/switch/page'),
	},
	{
		path: 'search-input',
		title: `Search Input`,
		loadChildren: () => import('./guides/components/search-input/page'),
	},
	{
		path: 'theme-manager',
		title: `Theme Manager`,
		loadChildren: () => import('./guides/components/theme-manager/page'),
	},
	{
		path: 'skeleton',
		title: `Skeleton`,
		loadChildren: () => import('./guides/components/skeleton/page'),
	},
	{
		path: 'status-tag',
		title: `Status Tag`,
		loadChildren: () => import('./guides/components/status-tag/page'),
	},],
	},
	
	{
		path: 'getting-started',
		title: `Getting Started`,
		children: [{
			path: '',
			redirectTo: 'installation',
			pathMatch: 'full'
		},
	{
		path: 'installation',
		title: `Installation`,
		loadChildren: () => import('./guides/getting-started/installation/page'),
	},
	{
		path: 'contributing',
		title: `Contributing`,
		loadChildren: () => import('./guides/getting-started/contributing/page'),
	},
	{
		path: 'introduction',
		title: `Introduction`,
		loadChildren: () => import('./guides/getting-started/introduction/page'),
	},],
	},
	
	{
		path: 'signals',
		title: `Signals`,
		children: [{
			path: '',
			redirectTo: 'breakpoint-observer',
			pathMatch: 'full'
		},
	{
		path: 'breakpoint-observer',
		title: `BreakpointObserver`,
		loadChildren: () => import('./guides/signals/breakpoint-observer/page'),
	},
	{
		path: 'computed-previous',
		title: `computedPrevious`,
		loadChildren: () => import('./guides/signals/computed-previous/page'),
	},
	{
		path: 'explicit-effect',
		title: `explicitEffect`,
		loadChildren: () => import('./guides/signals/explicit-effect/page'),
	},],
	},
	
	{
		path: 'pipes',
		title: `Pipes`,
		children: [{
			path: '',
			redirectTo: 'repeat',
			pathMatch: 'full'
		},
	{
		path: 'repeat',
		title: `VmrRepeatPipe`,
		loadChildren: () => import('./guides/pipes/repeat/page'),
	},
	{
		path: 'memoize-fn',
		title: `VmrMemoizeFnPipe`,
		loadChildren: () => import('./guides/pipes/memoize-fn/page'),
	},],
	},
	
	{
		path: 'directives',
		title: `Directives`,
		children: [{
			path: '',
			redirectTo: 'click-outside',
			pathMatch: 'full'
		},
	{
		path: 'click-outside',
		title: `ClickOutside`,
		loadChildren: () => import('./guides/directives/click-outside/page'),
	},],
	},
];
