import { NgComponentOutlet } from '@angular/common';
import { VmrToastService } from './toast.service';
import type { Toast, ToastPosition, ToastTypes } from './types';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { VmrProgressSpinner } from '@vermeer-corp/it-ng-components/loader';
import type { VmrToastContainerDefaultOptions } from './toast-container.component';
import { GAP, TIME_BEFORE_UNMOUNT, TOAST_DURATION, VmrCheckmarkIcon } from './internals';
import { explicitEffect, FaIconWrapperComponent, isString, toClassName } from '@vermeer-corp/it-ng-components/core';
import {
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  signal,
  untracked,
  viewChild,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'vmr-toast',
  templateUrl: 'toast.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    VmrCheckmarkIcon,
    NgComponentOutlet,
    VmrProgressSpinner,
    FaIconWrapperComponent
  ]
})
export class VmrToast implements OnDestroy, AfterViewInit {
  private readonly _toastService = inject(VmrToastService);

  private _remainingTime = 0;
  private _closeTimerStartTimeRef = 0;
  private _lastCloseTimerStartTimeRef = 0;
  private _timeoutId: undefined | ReturnType<typeof setTimeout>;
  private _unmountTimeoutId: undefined | ReturnType<typeof setTimeout>;

  /** @internal */
  readonly isString = isString;

  /** @internal */
  readonly toasts = this._toastService.toasts;

  /** @internal */
  readonly heights = this._toastService.heights;

  readonly toast = input.required<Toast>();
  readonly index = input.required<number>();
  readonly expanded = input.required<boolean>();
  readonly interacting = input.required<boolean>();
  readonly duration = input<number>(TOAST_DURATION);
  readonly position = input.required<ToastPosition>();
  readonly maxVisibleToasts = input.required<number>();
  readonly expandByDefault = input.required<boolean>();
  readonly showCloseButton = input.required<boolean>();
  readonly useAnimatedIcons = input.required<boolean>();
  readonly toastOptions = input.required<VmrToastContainerDefaultOptions>();

  /** @internal */
  readonly toastRef = viewChild.required<ElementRef<HTMLLIElement>>('toastRef');

  /** @internal */
  readonly mounted = signal<boolean>(false);

  /** @internal */
  readonly initialHeight = signal<number>(0);

  /** @internal */
  readonly offsetBeforeRemove = signal<number>(0);

  /** @internal */
  readonly markedForDelete = signal<boolean>(false);

  /** @internal */
  readonly toastType = computed<ToastTypes>(() => this.toast().type);

  /** @internal */
  readonly isToastVisible = computed<boolean>(() => this.index() + 1 <= this.maxVisibleToasts());

  /** @internal */
  readonly disableInteractiveTimerUpdates = computed<boolean>(() => {
    return this.toastType() === 'loading' || this.duration() === Number.POSITIVE_INFINITY;
  });

  /** @internal */
  readonly faIconType = computed<IconProp>(() => {
    switch (this.toastType()) {
      case 'info': return 'info-circle';
      case 'success': return'check-circle';
      case 'error': return 'exclamation-circle';
      case 'warning': return 'exclamation-triangle';
      default: return 'info-circle';
    }
  });

  /** @internal */
  readonly toastClassList = computed<string>(() =>
    toClassName(
      this.toastOptions().class,
      untracked(this.toast).class,
      `vmr-toast-type-${this.toastType()}`,
      this.mounted() && 'vmr-toast-mounted',
      this.index() === 0 && 'vmr-toast-first',
      this.isToastVisible() && 'vmr-toast-visible',
      this.markedForDelete() && 'vmr-toast-deleted'
    )
  );

  /** @internal */
  readonly toastStyle = computed<{[key: string]: unknown}>(() => ({
    '--index': `${this.index()}`,
    '--toasts-before': `${this.index()}`,
    '--z-index': `${this.toasts().length - this.index()}`,
    '--initial-height': this.expandByDefault() ? 'auto' : `${this.initialHeight()}px`,
    '--offset': `${this.markedForDelete() ? this.offsetBeforeRemove() : this.offset()}px`
  }));

  /** @internal */
  readonly offset = computed<number>(() => {
    const heights = this.heights();
    const heightIdx = heights.findIndex(h => h.toastId === untracked(this.toast).id) || 0;
    const toastsHeightBefore = heights.reduce((prev, cur, curIdx) => (curIdx >= heightIdx) ? prev : (prev + cur.height), 0);
    return Math.round((heightIdx * GAP) + toastsHeightBefore);
  });

  constructor() {
    effect((onCleanup) => {
      const expanded = this.expanded();
      const interacting = this.interacting();

      untracked(() => {
        if (!this.disableInteractiveTimerUpdates()) {
          (expanded || interacting)
            ? this.pauseTimer()
            : this.startTimer();
        }
      });

      onCleanup(() => this._checkClearTimeout());
    });

    // if the toast has been updated after the initial render, we want to reset the timer and set the remaining time to the new duration
    // else if toast has been marked for delete, then we start delete process
    explicitEffect(
      [this.toast],
      ([toast]) => {
        if (toast.updated) {
          this._checkClearTimeout();
          this._remainingTime = this.duration();
          this.startTimer();
        } else if (toast.delete) {
          this.deleteToast();
        }
      },
      {defer: true}
    );
  }

  ngAfterViewInit(): void {
    this._initToastSetup();
  }

  ngOnDestroy(): void {
    this._checkClearTimeout();
    this._unmountTimeoutId && clearTimeout(this._unmountTimeoutId);
    this._toastService.removeHeight(this.toast().id);
  }

  deleteToast(): void {
    this.markedForDelete.set(true);
    this.offsetBeforeRemove.set(this.offset());
    this._toastService.removeHeight(this.toast().id);
    this._unmountTimeoutId = setTimeout(() => this._toastService.dismiss(this.toast().id), TIME_BEFORE_UNMOUNT);
  }

  /**
   * Pause the timer on each hover.
   * If toast's duration changes, it will be out of sync w/ remainingAtTimeout, so we need to restart the timer with the new duration.
   */
  pauseTimer(): void {
    if (this._lastCloseTimerStartTimeRef < this._closeTimerStartTimeRef) {
      const elapsedTime = new Date().getTime() - this._closeTimerStartTimeRef;
      this._remainingTime = this._remainingTime - elapsedTime;
    }

    this._lastCloseTimerStartTimeRef = new Date().getTime();
  }

  startTimer(): void {
    this._closeTimerStartTimeRef = new Date().getTime();

    this._timeoutId = setTimeout(() => {
      this.toast().onAutoClose?.(this.toast());
      this.deleteToast();
    }, this._remainingTime);
  }

  onCloseButtonClick(): void {
    const { dismissible, onDismiss } = this.toast();
    if (dismissible) {
      this.deleteToast();
      onDismiss?.(this.toast());
    }
  }

  onCancelClick(): void {
    if (this.toast().dismissible) {
      this.deleteToast();
      this.toast().cancel?.onClick?.();
    }
  }

  onActionClick(event: MouseEvent): void {
    this.toast().action?.onClick?.(event);
    !event.defaultPrevented && this.deleteToast();
  }

  private _checkClearTimeout(): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
  }

  private _initToastSetup(): void {
    this._remainingTime = this.duration();
    this.mounted.set(true);
    const height = this.toastRef().nativeElement.getBoundingClientRect().height;
    this.initialHeight.set(height);
    this._toastService.addHeight({ height, toastId: this.toast().id });
  }
}