import { NgDocBlockquoteComponent, NgDocIconComponent } from '@ng-doc/ui-kit';
import { NgDocCodeComponent } from '@ng-doc/app/components/code';
import { NgDocDemoComponent } from '@ng-doc/app/components/demo';
import { NgDocDemoPaneComponent } from '@ng-doc/app/components/demo-pane';
import { NgDocHeadingAnchorComponent } from '@ng-doc/app/components/heading-anchor';
import { Renderer2 } from '@angular/core';
import { NgDocImageViewerComponent } from '@ng-doc/app/components/image-viewer';
import { NgDocMermaidViewerComponent } from '@ng-doc/app/components/mermaid-viewer';
import { NgDocPlaygroundComponent } from '@ng-doc/app/components/playground';
import { isPresent } from '@ng-doc/core/helpers/is-present';
import { NgDocTabsComponent } from '@ng-doc/app/components/tabs';
export * from '@ng-doc/app/processors/processors/link';
export * from '@ng-doc/app/processors/processors/tooltip';
const blockquoteProcessor = {
  component: NgDocBlockquoteComponent,
  selector: 'ng-doc-blockquote',
  extractOptions: element => ({
    content: [Array.from(element.childNodes)],
    inputs: {
      type: element.getAttribute('type') || 'default',
      icon: element.getAttribute('icon') ?? undefined
    }
  })
};
const codeProcessor = {
  component: NgDocCodeComponent,
  selector: 'pre code',
  nodeToReplace: element => element.closest('pre') ?? element,
  extractOptions: element => ({
    inputs: {
      name: element.parentElement?.getAttribute('name') || undefined,
      icon: element.parentElement?.getAttribute('icon') || undefined
    },
    content: [[element.closest('pre') ?? element]]
  })
};
const demoProcessor = {
  component: NgDocDemoComponent,
  selector: 'ng-doc-demo',
  extractOptions: element => ({
    inputs: {
      componentName: element.getAttribute('componentName') || undefined,
      options: JSON.parse(element.querySelector('#options')?.textContent ?? '{}')
    }
  })
};
const demoPaneProcessor = {
  component: NgDocDemoPaneComponent,
  selector: 'ng-doc-demo-pane',
  extractOptions: element => ({
    inputs: {
      componentName: element.getAttribute('componentName') || undefined,
      options: JSON.parse(element.querySelector('#options')?.textContent ?? '') || {}
    }
  })
};
const headingAnchorProcessor = {
  component: NgDocHeadingAnchorComponent,
  selector: 'ng-doc-heading-anchor',
  extractOptions: element => ({
    inputs: {
      classes: element.getAttribute('class')?.split(' ') || [],
      anchor: element.getAttribute('anchor') || ''
    }
  })
};
const iconProcessor = {
  component: NgDocIconComponent,
  selector: 'ng-doc-icon',
  extractOptions: element => ({
    inputs: {
      icon: element.getAttribute('icon') ?? '',
      size: Number(element.getAttribute('size')) || 16
    }
  })
};
const imageProcessor = {
  component: NgDocImageViewerComponent,
  selector: 'img:not([zoom="false"])',
  nodeToReplace: (element, injector) => {
    const renderer = injector.get(Renderer2);
    const anchor = renderer.createElement('div');
    return element.parentNode?.insertBefore(anchor, element) ?? element;
  },
  extractOptions: element => ({
    inputs: {
      src: element.getAttribute('src') ?? '',
      alt: element.getAttribute('alt') ?? ''
    },
    content: [[element]]
  })
};
const mermaidProcessor = {
  component: NgDocMermaidViewerComponent,
  selector: 'pre.mermaid',
  extractOptions: element => ({
    inputs: {
      graph: element.textContent ?? ''
    }
  })
};
const playgroundProcessor = {
  component: NgDocPlaygroundComponent,
  selector: 'ng-doc-playground',
  extractOptions: element => ({
    inputs: {
      id: element.getAttribute('id') || undefined,
      properties: JSON.parse(element.querySelector('#data')?.textContent?.replace(/\n/g, '\\n') ?? '') || undefined,
      pipeName: element.querySelector('#pipeName')?.textContent || undefined,
      selectors: (element.querySelector('#selectors')?.textContent || '').split(',').map(selector => selector.trim()).filter(isPresent),
      options: JSON.parse(element.querySelector('#options')?.textContent ?? '') || {}
    }
  })
};
const tabsProcessor = {
  component: NgDocTabsComponent,
  selector: 'ng-doc-tab',
  nodeToReplace: (element, injector) => {
    const renderer = injector.get(Renderer2);
    const anchor = renderer.createElement('div');
    return element.parentNode?.insertBefore(anchor, element) ?? element;
  },
  extractOptions: (element, root) => {
    const group = element.getAttribute('group') ?? '';
    const tabs = Array.from(root.querySelectorAll(`ng-doc-tab[group="${group}"]`));
    const mappedTabs = tabs.map(tab => ({
      title: tab.getAttribute('name') ?? '',
      content: tab,
      icon: tab.getAttribute('icon') || undefined,
      active: tab.hasAttribute('active')
    }));
    tabs.forEach(tab => tab.remove());
    return {
      inputs: {
        tabs: mappedTabs
      }
    };
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { blockquoteProcessor, codeProcessor, demoPaneProcessor, demoProcessor, headingAnchorProcessor, iconProcessor, imageProcessor, mermaidProcessor, playgroundProcessor, tabsProcessor };
