@let vmrToast = toast();
@let coordsArr = position().split('-');

<li
  #toastRef
  tabindex="0"
  class="vmr-toast"
  [style]="toastStyle()"
  [class]="toastClassList()"
  [class.vmr-toast-expanded]="expanded() || (expandByDefault() && mounted())"
  [attr.data-y-position]="coordsArr[0]"
  [attr.data-x-position]="coordsArr[1]"
  [attr.data-dismissible]="vmrToast.dismissible"
>
  @if (showCloseButton()) {
    <button
      aria-label="Close toast"
      class="vmr-toast-button-close"
      (click)="onCloseButtonClick()"
    >
      <fa-icon-wrapper icon="xmark" iconClass="vmr-icon" />
    </button>
  }

  @if (vmrToast.component) {
    <ng-container *ngComponentOutlet="vmrToast.component; inputs: vmrToast.componentProps" />
  } @else {
    @let type = toastType();

    @if (type !== 'default' || vmrToast.icon) {
      <div class="vmr-toast-icon">
        @if (type === 'loading' && !vmrToast.icon) {
          @if (isToastVisible()) {
            <vmr-progress-spinner color="white" diameter="30" />
          }
        } @else if (vmrToast.icon) {
          <ng-container *ngComponentOutlet="vmrToast.icon; inputs: vmrToast.componentProps" />
        } @else if (useAnimatedIcons() && type === 'success') {
          <vmr-checkmark-icon [animationDelay]="vmrToast.updated ? 0 : 150" />
        } @else {
          <fa-icon-wrapper [icon]="faIconType()" iconClass="vmr-icon" />
        }
      </div>
    }

    <div class="vmr-toast-content">
      @if (vmrToast.title) {
        <div class="vmr-toast-title">
          @if (isString(vmrToast.title)) {
            {{ vmrToast.title }}
          } @else {
            <ng-container *ngComponentOutlet="vmrToast.title; inputs: vmrToast.componentProps" />
          }
        </div>
      }

      @if (vmrToast.description) {
        <div
          class="vmr-toast-description"
          [style.font-size.px]="vmrToast.title ? 14 : null"
        >
          @if (isString(vmrToast.description)) {
            {{ vmrToast.description }}
          } @else {
            <ng-container *ngComponentOutlet="vmrToast.description; inputs: vmrToast.componentProps" />
          }
        </div>
      }
    </div>

    @if (vmrToast.cancel) {
      <button
        class="vmr-toast-button"
        (click)="onCancelClick()"
        [style]="vmrToast.cancelButtonStyle ?? toastOptions().cancelButtonStyle"
      >
        {{ vmrToast.cancel.label }}
      </button>
    }

    @if (vmrToast.action) {
      <button
        class="vmr-toast-button"
        (click)="onActionClick($event)"
        [style]="vmrToast.actionButtonStyle ?? toastOptions().actionButtonStyle"
      >
        {{ vmrToast.action.label }}
      </button>
    }
  }
</li>