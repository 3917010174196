@use '../../core/style/vars';

.vmr-checkmark-icon {
  display: block;
  width: inherit;
  height: inherit;

  .vmr-checkmark {
    display: block;
    stroke-width: 5;
    border-radius: 50%;
    stroke-miterlimit: 10;
    stroke: vars.$vmr-secondary-green;
    box-shadow: inset 0px 0px 0px vars.$vmr-white;
    animation: checkmark-fill .4s ease-in-out .4s forwards, checkmark-scale .3s ease-in-out .9s both;
  }

  .vmr-checkmark-circle {
    fill: none;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke: vars.$vmr-white;
    animation: checkmark-stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
  }

  .vmr-checkmark-check {
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    transform-origin: 50% 50%;
    animation: checkmark-stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
  }
}

@keyframes checkmark-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkmark-fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px vars.$vmr-white;
  }
}