@if (toastCount() > 0) {
  <section
    tabindex="-1"
    aria-live="polite"
    aria-atomic="false"
    id="vmr-toast-container"
    class="vmr-{{theme()}}-mode"
    aria-relevant="additions text"
    aria-label="Toast Notifications"
  >
    @for (pos of possiblePositions(); track pos) {
      @let coordsArr = pos.split('-');

      <ol
        #listRef
        tabindex="-1"
        [style]="toastListCssVars()"
        class="vmr-toast-ordered-list"
        [attr.data-y-position]="coordsArr[0]"
        [attr.data-x-position]="coordsArr[1]"
        (blur)="handleBlur($event)"
        (focus)="handleFocus($event)"
        (mousemove)="expanded.set(true)"
        (mouseenter)="expanded.set(true)"
        (mouseleave)="handleMouseLeave()"
        (pointerup)="interacting.set(false)"
        (pointerdown)="handlePointerDown($event)"
      >
        @for (toast of toasts() | toastFilter : $index : pos; track toast.id) {
          <vmr-toast
            [toast]="toast"
            [index]="$index"
            [expanded]="expanded()"
            [toastOptions]="_defaults"
            [interacting]="interacting()"
            [expandByDefault]="expandByDefault()"
            [maxVisibleToasts]="maxVisibleToasts()"
            [useAnimatedIcons]="useAnimatedIcons()"
            [duration]="toast.duration ?? duration()"
            [position]="toast.position ?? position()"
            [showCloseButton]="toast.showCloseButton ?? showCloseButton()"
          />
        }
      </ol>
    }
  </section>
}