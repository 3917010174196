import { RouterOutlet } from '@angular/router';
import { ToastStateService } from './core/services';
import { NgDocTooltipDirective } from '@ng-doc/ui-kit';
import { NgDocThemeService } from '@ng-doc/app/services/theme';
import { GitHubIcon, LightbulbIcon, REPOSITORY_URL } from './core';
import { explicitEffect } from '@vermeer-corp/it-ng-components/core';
import { VmrToastContainer } from '@vermeer-corp/it-ng-components/toast';
import { VmrBackToTop } from '@vermeer-corp/it-ng-components/back-to-top';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { VmrLogo, VmrLogoVertical } from '@vermeer-corp/it-ng-components/logo';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgDocRootComponent, NgDocNavbarComponent, NgDocSidebarComponent } from '@ng-doc/app';
import { VmrThemeManager, VmrThemeToggle } from '@vermeer-corp/it-ng-components/theme-manager';
import {
  faStar,
  faCode,
  faXmark,
  faCheck,
  faMinus,
  faSearch,
  faComment,
  faAngleUp,
  faInfoCircle,
  faXmarkCircle,
  faCheckCircle,
  faMailboxFlagUp,
  faExternalLinkAlt,
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/pro-solid-svg-icons';

@Component({
  standalone: true,
  selector: 'app-root',
  styleUrl: 'app.component.scss',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    VmrLogo,
    GitHubIcon,
    VmrBackToTop,
    RouterOutlet,
    LightbulbIcon,
    VmrThemeToggle,
    VmrLogoVertical,
    VmrToastContainer,
    FontAwesomeModule,
    NgDocRootComponent,
    NgDocNavbarComponent,
    NgDocSidebarComponent,
    NgDocTooltipDirective
  ]
})
export class AppComponent {
  private readonly _ngDocTheme = inject(NgDocThemeService);
  private readonly _themeManager = inject(VmrThemeManager);
  private readonly _toastState = inject(ToastStateService);

  readonly _repoUrl = REPOSITORY_URL;
  readonly _issuesUrl = `${REPOSITORY_URL}/issues`;
  readonly _year = new Date().getFullYear();

  readonly theme = this._themeManager.theme;
  readonly position = this._toastState.position.asReadonly();
  readonly expand = this._toastState.expandByDefault.asReadonly();
  readonly closeButton = this._toastState.showCloseButton.asReadonly();

  constructor() {
    this._addFaIcons();
    this._ngDocTheme.set(this.theme());

    // Keep our theme synchronized with ng-doc's `NgDocThemeService`.
    explicitEffect(
      [this.theme],
      ([theme]) => this._ngDocTheme.set(theme),
      {defer: true}
    );
  }

  private _addFaIcons(): void {
    inject(FaIconLibrary).addIcons(
      faStar,
      faCode,
      faXmark,
      faCheck,
      faMinus,
      faSearch,
      faComment,
      faAngleUp,
      faInfoCircle,
      faCheckCircle,
      faXmarkCircle,
      faMailboxFlagUp,
      faExternalLinkAlt,
      faExclamationCircle,
      faExclamationTriangle
    );
  }
}
