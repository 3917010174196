import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { delay, of } from 'rxjs';

/**
 * Animated checkmark icon. Styled to match FontAwesome solid `checkmark-circle` icon.
 * `renderIcon` is used to delay the animation of the icon until the toast is transitioned into view.
 */
@Component({
  standalone: true,
  selector: 'vmr-checkmark-icon',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: 'checkmark-icon.component.scss',
  template: `
    @if (renderIcon()) {
      <svg
        viewBox="0 0 52 52"
        class="vmr-checkmark"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          fill="none"
          cx="26" cy="26" r="25"
          class="vmr-checkmark-circle"
        />
        <path
          fill="none"
          class="vmr-checkmark-check"
          d="M14.1 27.2l7.1 7.2 16.7-16.8"
        />
      </svg>
    }
  `,
  host: {
    class: 'vmr-checkmark-icon'
  }
})
export class VmrCheckmarkIcon {
  readonly animationDelay = input<number | undefined>(undefined);

  readonly renderIcon = toSignal<boolean, false>(
    of(true).pipe(delay(this.animationDelay() ?? 150)),
    {initialValue: false}
  );
}