import type { ToastPosition, Toast } from '../types';
import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toastFilter'
})
export class ToastFilterPipe implements PipeTransform {
  transform(toasts: Toast[], index: number, position: ToastPosition): Toast[] {
    return toasts.filter(t => (!t.position && index === 0) || t.position === position);
  }
}