@use '../core/style/vars';
@use '../core/style/button-common';

$vmr-theme-toggle-size: 40px;

.vmr-theme-toggle {
  align-items: center;
  display: inline-block;
  width: $vmr-theme-toggle-size;
  height: $vmr-theme-toggle-size;

  .vmr-theme-toggle-button {
    @include button-common.vmr-icon-button-base($vmr-theme-toggle-size);
    svg {
      overflow: hidden;
    }
  }
}