import type { Route } from '@angular/router';
import { NG_DOC_ROUTING } from '@ng-doc/generated';

export const ROUTES: Route[] = [
  ...NG_DOC_ROUTING,
  {
    path: '**',
    redirectTo: '/getting-started/introduction',
    pathMatch: 'full'
  }
];