import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { VmrThemeManager } from './theme-manager.service';

/**
 * `VmrThemeToggle` is responsible for toggling between light and dark themes.
 * See https://ui.vermeer.com/components/theme-manager.
 *
 * @example
 * Just drop the component in as is. There are no `@Inputs` or `@Outputs`:
 * ```html
 * <vmr-theme-toggle />
 * ```
 */
@Component({
  standalone: true,
  selector: 'vmr-theme-toggle',
  styleUrl: 'theme-toggle.component.scss',
  templateUrl: 'theme-toggle.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'vmr-theme-toggle'
  }
})
export class VmrThemeToggle {
  readonly _themeManager = inject(VmrThemeManager);
  readonly isDarkMode = this._themeManager.isDarkMode;

  toggleTheme(): void {
    const nextTheme = this.isDarkMode() ? 'light' : 'dark';
    this._themeManager.setTheme(nextTheme);
  }
}
