import { Injector } from '@angular/core';
import { NG_DOC_COMPONENT_CONTEXT } from '@ng-doc/ui-kit/tokens';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';

// eslint-disable-next-line @typescript-eslint/ban-types
class NgDocComponentContent extends PolymorpheusComponent {
  constructor(component, injectorRef) {
    super(component);
    this.component = component;
    this.injectorRef = injectorRef;
  }
  createInjector(injector, useValue) {
    return Injector.create({
      parent: this.injectorRef || injector,
      providers: [{
        provide: NG_DOC_COMPONENT_CONTEXT,
        useValue
      }]
    });
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgDocComponentContent };
