// @ts-nocheck
import { Provider } from '@angular/core';
import { NG_DOC_CONTEXT, NG_DOC_ROUTE_PREFIX, NG_DOC_SHIKI_THEME } from '@ng-doc/app/tokens';

export function provideNgDocContext(): Provider[] {
  return [{
    provide: NG_DOC_CONTEXT,
    useValue: {
      navigation: [
          
        {
          title: `Getting Started`,
          route: '/getting-started',
          expandable: true,
          expanded: false,
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Introduction`,
          route: '/getting-started/introduction',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Installation`,
          route: '/getting-started/installation',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Contributing`,
          route: '/getting-started/contributing',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Components`,
          route: '/components',
          expandable: true,
          expanded: false,
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `Alert`,
          route: '/components/alert',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Back To Top`,
          route: '/components/back-to-top',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Badge`,
          route: '/components/badge',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Button`,
          route: '/components/button',
          order: 4,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Card`,
          route: '/components/card',
          order: 5,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Divider`,
          route: '/components/divider',
          order: 6,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Info Window`,
          route: '/components/info-window',
          order: 7,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Loader`,
          route: '/components/loader',
          order: 8,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Logo`,
          route: '/components/logo',
          order: 9,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Layout`,
          route: '/components/navigation',
          order: 10,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Search Input`,
          route: '/components/search-input',
          order: 10,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Select`,
          route: '/components/select',
          order: 11,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Sheet`,
          route: '/components/sheet',
          order: 12,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Skeleton`,
          route: '/components/skeleton',
          order: 13,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Status Tag`,
          route: '/components/status-tag',
          order: 14,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Switch`,
          route: '/components/switch',
          order: 15,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Theme Manager`,
          route: '/components/theme-manager',
          order: 16,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `Toast`,
          route: '/components/toast',
          order: 17,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Directives`,
          route: '/directives',
          expandable: true,
          expanded: false,
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `ClickOutside`,
          route: '/directives/click-outside',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Pipes`,
          route: '/pipes',
          expandable: true,
          expanded: false,
          order: 4,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `VmrMemoizeFnPipe`,
          route: '/pipes/memoize-fn',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `VmrRepeatPipe`,
          route: '/pipes/repeat',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
          
        {
          title: `Signals`,
          route: '/signals',
          expandable: true,
          expanded: false,
          order: 5,
          hidden: false,
          metadata: {"description":"","tags":{}},
          children: [
              
        {
          title: `BreakpointObserver`,
          route: '/signals/breakpoint-observer',
          order: 1,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `computedPrevious`,
          route: '/signals/computed-previous',
          order: 2,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
              
        {
          title: `explicitEffect`,
          route: '/signals/explicit-effect',
          order: 3,
          hidden: false,
          metadata: {"description":"","tags":{}},
        },
          ]
        },
      ],
    },
  }, {
    provide: NG_DOC_ROUTE_PREFIX,
    useValue: '',
  }, {
    provide: NG_DOC_SHIKI_THEME,
    useValue: {
      light: 'github-light',
      dark: 'github-dark',
    },
  }];
}
