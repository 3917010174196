import { NgDocPageLinkComponent } from '@ng-doc/app/components/page-link';
const linkProcessor = {
  component: NgDocPageLinkComponent,
  selector: 'a',
  extractOptions: element => ({
    inputs: {
      href: element.getAttribute('href') ?? '',
      classes: element.getAttribute('class') ?? ''
    },
    content: [Array.from(element.childNodes)]
  })
};

/**
 * Generated bundle index. Do not edit.
 */

export { linkProcessor };
