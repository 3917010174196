@use '../core/style/vars';

#vmr-toast-container {
  &.vmr-dark-mode > .vmr-toast-ordered-list {
    --normal-bg: #{vars.$vmr-dark-alert-bg};
    --normal-text: var(--gray1);
    --success-bg: #{vars.$vmr-dark-alert-bg};
    --success-text: #1AAE1A;
    --info-bg: #{vars.$vmr-dark-alert-bg};
    --info-text: #1A99C7;
    --warning-bg: #{vars.$vmr-dark-alert-bg};
    --warning-text: #{vars.$vmr-yellow};
    --error-bg: #{vars.$vmr-dark-alert-bg};
    --error-text: hsl(358, 100%, 81%);

    .vmr-toast {
      .vmr-toast-button.vmr-toast-button-cancel {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  > .vmr-toast-ordered-list {
    --mobile-offset: 16px;
    --toast-button-margin-end: 0;
    --toast-button-margin-start: auto;
    --normal-bg: #2f3033;
    --normal-text: #{vars.$vmr-white};
    --success-bg: #{vars.$vmr-secondary-green};
    --success-text: #{vars.$vmr-white};
    --info-text: #{vars.$vmr-white};
    --info-bg: #{vars.$vmr-notification-blue};
    --warning-bg: #{vars.$vmr-accent};
    --warning-text: #{vars.$vmr-dark-grey};
    --error-bg: #{vars.$vmr-action-red};
    --error-text: #{vars.$vmr-white};

    margin: 0;
    padding: 0;
    outline: none;
    position: fixed;
    list-style: none;
    z-index: 999999999;
    width: var(--width);
    box-sizing: border-box;
    font-family: vars.$vmr-fonts-base;

    &[data-x-position='center'] {
      left: 50%;
      transform: translateX(-50%);
    }

    &[data-x-position='right'] {
      right: max(var(--offset), env(safe-area-inset-right));
    }

    &[data-x-position='left'] {
      left: max(var(--offset), env(safe-area-inset-left));
    }

    &[data-y-position='top'] {
      top: max(var(--offset), env(safe-area-inset-top));
    }

    &[data-y-position='bottom'] {
      bottom: max(var(--offset), env(safe-area-inset-bottom));
    }

    @media (max-width: 600px) {
      position: fixed;
      right: var(--mobile-offset);
      left: var(--mobile-offset);
      width: 100%;

      &[data-x-position='center'] {
        transform: none;
        left: var(--mobile-offset);
        right: var(--mobile-offset);
      }

      &[data-x-position='left'] {
        left: var(--mobile-offset);
      }

      &[data-y-position='top'] {
        top: var(--mobile-offset);
      }

      &[data-y-position='bottom'] {
        bottom: var(--mobile-offset);
      }
    }

    .vmr-toast {
      --y: translateY(100%);
      --lift-amount: calc(var(--lift) * var(--gap));
      transform: var(--y);
      z-index: var(--z-index);
      opacity: 0;
      outline: none;
      touch-action: none;
      position: absolute;
      box-sizing: border-box;
      overflow-wrap: anywhere;
      padding: 12px 20px;
      background: var(--normal-bg);
      color: var(--normal-text);
      border-radius: vars.$vmr-container-shape;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
      width: var(--width);
      font-size: 16px;
      display: flex;
      align-items: center;
      min-height: 64px;
      transition: transform 400ms, opacity 400ms, height 400ms, box-shadow 200ms;

      > * {
        transition: opacity 400ms;
      }

      &:focus-visible {
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(0, 0, 0, 0.26);
      }

      &::after {
        left: 0;
        content: '';
        width: 100%;
        bottom: 100%;
        position: absolute;
        height: calc(var(--gap) + 1px);
      }

      &[data-x-position='right'] {
        right: 0;
      }

      &[data-x-position='left'] {
        left: 0;
      }

      &[data-y-position='top'] {
        top: 0;
        --lift: 1;
        --y: translateY(-100%);
        --lift-amount: calc(1 * var(--gap));
      }

      &[data-y-position='bottom'] {
        bottom: 0;
        --lift: -1;
        --y: translateY(100%);
        --lift-amount: calc(var(--lift) * var(--gap));
      }

      &.vmr-toast-deleted::before {
        inset: 0;
        content: '';
        position: absolute;
        transform: scaleY(2);
      }

      &.vmr-toast-mounted {
        --y: translateY(0);
        opacity: 1;
      }

      &.vmr-toast-mounted.vmr-toast-expanded {
        --y: translateY(calc(var(--lift) * var(--offset)));
        height: var(--initial-height);
      }

      &:not(.vmr-toast-visible) {
        opacity: 0;
        pointer-events: none;
      }

      &:not(.vmr-toast-first):not(.vmr-toast-expanded) {
        --scale: var(--toasts-before) * 0.05 + 1;
        --y: translateY(calc(var(--lift-amount) * var(--toasts-before))) scale(calc(-1 * var(--scale)));
        height: var(--front-toast-height);

        > * {
          opacity: 0;
        }
      }

      &.vmr-toast-deleted.vmr-toast-first {
        --y: translateY(calc(var(--lift) * -100%));
        opacity: 0;
      }

      &.vmr-toast-expanded:not(.vmr-toast-first).vmr-toast-deleted {
        --y: translateY(calc(var(--lift) * var(--offset) + var(--lift) * -100%));
        opacity: 0;
      }

      &.vmr-toast-deleted:not(.vmr-toast-first):not(.vmr-toast-expanded) {
        --y: translateY(40%);
        opacity: 0;
        transition: transform 500ms, opacity 200ms;
      }

      // Bump up the height to make sure hover state doesn't get set to false.
      &.vmr-toast-deleted:not(.vmr-toast-first)::before {
        height: calc(var(--initial-height) + 20%);
      }

      @media (max-width: 600px) {
        left: 0;
        right: 0;
        width: calc(100% - (var(--mobile-offset) * 2));
      }

      .vmr-toast-content {
        gap: 2px;
        display: flex;
        line-height: normal;
        flex-direction: column;

        .vmr-toast-title {
          color: inherit;
          font-weight: 700;
          letter-spacing: .01em;
        }

        .vmr-toast-description {
          color: inherit;
          font-weight: 400;
        }
      }

      .vmr-toast-icon {
        width: 30px;
        height: 30px;
        font-size: 30px;
        display: flex;
        flex-shrink: 0;
        margin-right: 14px;
        position: relative;
        align-items: center;
        justify-content: flex-start;

        > * {
          flex-shrink: 0;
        }
      }

      .vmr-toast-button {
        height: 26px;
        min-width: 50px;
        border-radius: 2px;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        flex-shrink: 0;
        cursor: pointer;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-family: vars.$vmr-fonts-base;
        background-color: transparent;
        color: vars.$vmr-white;
        border: 1px solid vars.$vmr-white;

        &:first-of-type {
          margin-right: var(--toast-button-margin-end);
          margin-left: var(--toast-button-margin-start);
        }

        &:nth-of-type(2) {
          margin-left: 8px;
        }
      }

      .vmr-toast-button-close {
        height: 20px;
        width: 20px;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: var(--normal-bg);
        color: var(--normal-text);
        border: 0;
        transform: translate(-35%, -35%);
        border-radius: 9999px;
        cursor: pointer;
        z-index: 1;
        box-shadow: 0 0 2px 1px rgba(237, 225, 226, .425);
        transition: opacity 100ms, background 200ms;

        &:focus-visible {
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(0, 0, 0, 0.2);
        }

        .vmr-icon {
          font-size: 13px;
        }
      }

      .vmr-progress-spinner {
        inset: 0;
        z-index: 10;
        overflow: visible;
        position: absolute;
      }

      &.vmr-toast-type-error {
        &, .vmr-toast-button-close {
          background: var(--error-bg);
          color: var(--error-text);
        }
      }

      &.vmr-toast-type-success {
        &, .vmr-toast-button-close {
          background: var(--success-bg);
          color: var(--success-text);
        }
      }

      &.vmr-toast-type-warning {
        &, .vmr-toast-button-close {
          background: var(--warning-bg);
          color: var(--warning-text);
        }

        .vmr-toast-button-close {
          box-shadow: 0 0 2px 1px rgba(47, 48, 51, .22);
        }

        .vmr-toast-button {
          color: var(--warning-text);
          border-color: var(--warning-text);
        }
      }

      &.vmr-toast-type-info {
        &, .vmr-toast-button-close {
          background: var(--info-bg);
          color: var(--info-text);
        }
      }
    }
  }
}