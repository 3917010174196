import { linkProcessor, iconProcessor, headingAnchorProcessor, blockquoteProcessor, tooltipProcessor, mermaidProcessor, codeProcessor, demoProcessor, demoPaneProcessor, playgroundProcessor, tabsProcessor, imageProcessor } from '@ng-doc/app/processors/processors';
const NG_DOC_DEFAULT_PAGE_PROCESSORS = [
/**
 * The order of the directives is important.
 * The higher the directive is in the list, the earlier it will be run.
 */
linkProcessor, iconProcessor, headingAnchorProcessor, blockquoteProcessor, tooltipProcessor, mermaidProcessor, codeProcessor, demoProcessor, demoPaneProcessor, playgroundProcessor, tabsProcessor, imageProcessor];

/**
 * Generated bundle index. Do not edit.
 */

export { NG_DOC_DEFAULT_PAGE_PROCESSORS };
