import { ViewportScroller } from '@angular/common';
import { APP_INITIALIZER, inject } from '@angular/core';
import { NgDocHighlighterService } from '@ng-doc/app/services/highlighter';
import { provideNgDocUiKitConfig } from '@ng-doc/ui-kit';

/**
 * Provides the NgDoc application configuration.
 * @param config - The optional application configuration.
 */
function provideNgDocApp(config) {
  return [/* --- Viewport Scroller --- */
  {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ViewportScroller],
    useFactory: viewportScroller => {
      return () => viewportScroller.setOffset([0, 120]);
    }
  }, /* --- Shiki --- */
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: () => {
      const highlighter = inject(NgDocHighlighterService);
      return () => highlighter.initialize(config?.shiki);
    }
  }, /* --- UiKit --- */
  ...provideNgDocUiKitConfig(config?.uiKit)];
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideNgDocApp };
