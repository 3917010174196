<!-- VmrToastContainer -->
<vmr-toast-container
  [theme]="$any(theme())"
  [position]="position()"
  [expandByDefault]="expand()"
  [showCloseButton]="closeButton()"
/>

<!-- Back to top scroll button -->
<vmr-back-to-top />

<!-- Template overrides -->
<ng-template #footerContent>
  <div class="docs-footer">
    Copyright &copy; {{_year}} Vermeer Corporation.<br />All rights reserved.
  </div>
</ng-template>

<!-- Layout -->
<ng-doc-root [footerContent]="footerContent">
  <ng-doc-navbar class="ng-doc-navbar">
    <a
      href="/"
      ngDocNavbarLeft
      class="docs-logo"
    >
      <h3>
        <vmr-logo />
        <vmr-logo-vertical />
      </h3>
    </a>

    <div ngDocNavbarRight class="docs-header-controls">
      <vmr-theme-toggle />

      <a
        target="_blank"
        href="{{_repoUrl}}"
        ngDocTooltip="Repository on GitHub"
      ><app-github-icon /></a>

      <a
        target="_blank"
        href="{{_issuesUrl}}"
        ngDocTooltip="Make a suggestion"
      ><app-lightbulb-icon /></a>
    </div>
  </ng-doc-navbar>

  <ng-doc-sidebar class="ng-doc-sidebar" />
  <router-outlet />
</ng-doc-root>
