import { ROUTES } from './routes';
import { provideNgDocContext } from '@ng-doc/generated';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { provideVmrThemeManager } from '@vermeer-corp/it-ng-components/theme-manager';
import {
  provideNgDocApp,
  providePageSkeleton,
  provideSearchEngine,
  NgDocDefaultSearchEngine,
  provideMainPageProcessor,
  NG_DOC_DEFAULT_PAGE_SKELETON,
  NG_DOC_DEFAULT_PAGE_PROCESSORS,
} from '@ng-doc/app';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideNgDocContext(),
    provideNgDocApp({
      shiki: {
        themes: [
          import('shiki/themes/github-dark.mjs'),
          import('shiki/themes/github-light.mjs'),
        ],
        theme: {
          dark: 'github-dark',
          light: 'github-light',
        },
      },
    }),
    provideVmrThemeManager({
      track: false,
      useStorage: false,
      themeDefault: 'light'
    }),
    provideSearchEngine(NgDocDefaultSearchEngine),
    providePageSkeleton(NG_DOC_DEFAULT_PAGE_SKELETON),
    provideMainPageProcessor(NG_DOC_DEFAULT_PAGE_PROCESSORS),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      ROUTES,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withViewTransitions()
    )
  ]
};
