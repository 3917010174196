import { VmrToast } from './toast.component';
import type { ToastPosition } from './types';
import { VmrToastService } from './toast.service';
import { cssUnitAttribute, explicitEffect, isNumber } from '@vermeer-corp/it-ng-components/core';
import {
  GAP,
  TOAST_WIDTH,
  TOAST_DURATION,
  VIEWPORT_OFFSET,
  ToastFilterPipe,
  MAX_VISIBLE_TOASTS
} from './internals';
import {
  inject,
  input,
  signal,
  computed,
  Component,
  viewChild,
  ElementRef,
  InjectionToken,
  numberAttribute,
  booleanAttribute,
  ViewEncapsulation,
  ChangeDetectionStrategy
} from '@angular/core';

/** Default `VmrToastContainer` options that can be overridden. */
export interface VmrToastContainerDefaultOptions {
  /** The classes applied to the toast element. */
  class?: string;
  /** The duration of the toast in milliseconds. Defaults to `4000`. */
  duration?: number;
  /** Max number of toasts visible on screen. Defaults to `3`. */
  maxVisibleToasts?: number;
  /** Place where the toasts will be rendered. Defaults to `top-center`. */
  position?: ToastPosition;
  /** Offset from the edges of the screen. Defaults to `24px`. */
  viewportOffset?: string;
  /** The width of the toast. Defaults to `356px`. */
  width?: string | number;
  /** Adds a close button to all toasts. Defaults to `false`. */
  showCloseButton?: boolean;
  /** The CSS styles applied to the cancel button element. */
  cancelButtonStyle?: string;
  /** The CSS styles applied to the action button element. */
  actionButtonStyle?: string;
  /** Uses an animated version of an icon if available - e.g. success checkmark. */
  useAnimatedIcons?: boolean;
}

/** Injection token to be used to override the default options for `VmrToastContainer`. */
export const VMR_TOAST_CONTAINER_DEFAULT_OPTIONS = new InjectionToken<VmrToastContainerDefaultOptions>(
  'vmr-toast-container-default-options',
  {
    providedIn: 'root',
    factory: VMR_TOAST_CONTAINER_DEFAULT_OPTIONS_FACTORY,
  },
);

function VMR_TOAST_CONTAINER_DEFAULT_OPTIONS_FACTORY(): VmrToastContainerDefaultOptions {
  return {
    width: TOAST_WIDTH,
    position: 'top-center',
    useAnimatedIcons: true,
    duration: TOAST_DURATION,
    viewportOffset: VIEWPORT_OFFSET,
    maxVisibleToasts: MAX_VISIBLE_TOASTS
  };
}

/**
 * `VmrToastContainer` is the parent component for all toasts. It should be rendered in
 * the root template of your application and accepts a variety of global options.
 *
 * See https://ui.vermeer.com/components/toast.
 */
@Component({
  standalone: true,
  selector: 'vmr-toast-container',
  styleUrl: 'toast-container.component.scss',
  templateUrl: 'toast-container.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [VmrToast, ToastFilterPipe]
})
export class VmrToastContainer {
  private _isFocusWithinRef = false;
  private _lastFocusedElementRef: HTMLElement | null = null;
  private readonly _toastService = inject(VmrToastService);

  /** @internal */
  protected _defaults = inject<VmrToastContainerDefaultOptions>(VMR_TOAST_CONTAINER_DEFAULT_OPTIONS);

  /**
   * Toasts state value.
   * @internal
   */
  readonly toasts = this._toastService.toasts;

  /**
   * Toast heights state value - linked via toast id.
   * @internal
   */
  readonly heights = this._toastService.heights;

  /** Toast theme. */
  readonly theme = input<'light' | 'dark'>('light');

  /** Place where the toasts will be rendered. Defaults to `top-center`. */
  readonly position = input<ToastPosition>(this._defaults.position ?? 'top-center');

  /** Offset from the edges of the screen. Defaults to `32px`. */
  readonly offset = input<string>(this._defaults.viewportOffset ?? VIEWPORT_OFFSET);

  /** Toasts will be expanded by default. Defaults to `false`. */
  readonly expandByDefault = input<boolean, unknown>(false, {
    transform: booleanAttribute
  });

  /** Default toast width. Defaults to `356px`. */
  readonly width = input<string | null, string | number | null | undefined>(
    cssUnitAttribute(this._defaults.width ?? TOAST_WIDTH), {
    transform: cssUnitAttribute
  });

  /** Adds a close button to all toasts. Defaults to `false`. */
  readonly showCloseButton = input<boolean, unknown>(!!this._defaults.showCloseButton, {
    transform: booleanAttribute
  });

  /** The duration of the toast in milliseconds. Defaults to `4000`. */
  readonly duration = input<number, unknown>(this._defaults.duration ?? TOAST_DURATION, {
    transform: numberAttribute
  });

  /**
   * Uses an animated version of an icon if available - e.g. success checkmark.
   * Defaults to `true,`
   */
  readonly useAnimatedIcons = input<boolean, unknown>(!!this._defaults.useAnimatedIcons, {
    transform: booleanAttribute
  });

  /** Max number of toasts visible on screen. Defaults to `3`. */
  readonly maxVisibleToasts = input<number, unknown>(this._defaults.maxVisibleToasts ?? MAX_VISIBLE_TOASTS, {
    transform: numberAttribute
  });

  /**
   * The expanded state for toasts.
   * @internal
   */
  readonly expanded = signal(false);

  /**
   * Value is true on mouseenter event for toast list element.
   * @internal
   */
  readonly interacting = signal(false);

  /**
   * Parent list element that wraps toasts.
   * @internal
   */
  readonly listRef = viewChild<ElementRef<HTMLOListElement>>('listRef');

  /**
   * Total count of toasts.
   * @internal
   */
  readonly toastCount = computed<number>(() => this.toasts()?.length || 0);

  /**
   * Tracks all unique position values for all toasts.
   * @internal
   */
  readonly possiblePositions = computed<ToastPosition[]>(() =>
    Array.from(
      new Set(
        [
          this.position(),
          ...this.toasts().filter(t => t.position)
            .map(t => t.position)
        ]
      )
    ) as ToastPosition[]
  );

  /**
   * CSS vars for parent OL element.
   * @internal
   */
  readonly toastListCssVars = computed(() => {
    const width = this.width();
    const offset = this.offset();
    const heights = this.heights();

    return {
      '--width': width,
      '--gap': `${GAP}px`,
      '--offset': isNumber(offset) ? `${offset}px` : offset,
      '--front-toast-height': `${heights[0]?.height || 0}px`
    };
  });

  constructor() {
    explicitEffect(
      [this.toastCount],
      ([toastCount]) => {
        if (toastCount >= 1) {
          this.expanded.set(false);
        }
      },
      {defer: true}
    );
  }

  /** Callback for mouseleave event bound to toast list element. */
  handleMouseLeave(): void {
    if (!this.interacting()) {
      this.expanded.set(false);
    }
  }

  /** Callback for pointerdown event bound to toast list element. */
  handlePointerDown(e: MouseEvent) {
    if (!this._isNotDismissableEvent(e)) {
      this.interacting.set(true);
    }
  }

  /** Callback for focus event bound to toast list element. */
  handleFocus(e: FocusEvent): void {
    if (!this._isNotDismissableEvent(e) && !this._isFocusWithinRef) {
      this._isFocusWithinRef = true;
      this._lastFocusedElementRef = e.relatedTarget as HTMLElement;
    }
  }

  /** Callback for blur event bound to toast list element. */
  handleBlur(e: FocusEvent): void {
    if (
      this._isFocusWithinRef &&
      !(e.target as HTMLOListElement).contains(e.relatedTarget as HTMLElement)
    ) {
      this._isFocusWithinRef = false;
      if (this._lastFocusedElementRef) {
        this._lastFocusedElementRef.focus({ preventScroll: true });
        this._lastFocusedElementRef = null;
      }
    }
  }

  private _isNotDismissableEvent(e: Event): boolean {
    return e.target instanceof HTMLElement && e.target.dataset['dismissible'] === 'false';
  }
}